/* global WebFont, $, document */

WebFont.load({
  google: {
    families: ['Source Sans Pro:200,400,600', 'Raleway:200,400,600', 'Material Icons']
  }
});

function toggleVisibility (el) {
  el = $(el);
  if (el.css('display') === 'none') {
    el.css('display', 'block');
  } else {
    el.css('display', 'none');
  }
}

$(document).ready(function () {
  $('.toggleMenu').on('click', function () {
    toggleVisibility('.headerCont');
  });
  $('a.innerNavLink').on('click', function () {
    // if we had the drop-down header when clicking, it should be hidden on
    // click. if we didn't, it means we're in full page, full width nav mode
    // and there's no need to hide anything
    if ($('.headerCont').css('display') === 'block') {
      toggleVisibility('.headerCont');
    }
  });
});
