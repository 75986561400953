/* MIT https://github.com/kenwheeler/cash */
(function(){
'use strict';var e=document,g=window,k=e.documentElement,m=e.createElement.bind(e),p=m("div"),q=m("table"),aa=m("tbody"),r=m("tr"),t=Array.isArray,v=Array.prototype,w=v.filter,x=v.indexOf,ba=v.map,ca=v.push,y=v.slice,z=v.some,da=v.splice,ea=/^#[\w-]*$/,fa=/^\.[\w-]*$/,ha=/<.+>/,ia=/^\w+$/;function A(a,b){void 0===b&&(b=e);return b&&9===b.nodeType||b&&1===b.nodeType?fa.test(a)?b.getElementsByClassName(a.slice(1)):ia.test(a)?b.getElementsByTagName(a):b.querySelectorAll(a):[]}
var B=function(){function a(a,c){void 0===c&&(c=e);if(a){if(a instanceof B)return a;var b=a;if(C(a)){if(b=c instanceof B?c[0]:c,b=ea.test(a)?b.getElementById(a.slice(1)):ha.test(a)?D(a):A(a,b),!b)return}else if(E(a))return this.ready(a);if(b.nodeType||b===g)b=[b];this.length=b.length;a=0;for(c=this.length;a<c;a++)this[a]=b[a]}}a.prototype.init=function(b,c){return new a(b,c)};return a}(),F=B.prototype,G=F.init;G.fn=G.prototype=F;F.length=0;F.splice=da;
"function"===typeof Symbol&&(F[Symbol.iterator]=v[Symbol.iterator]);F.map=function(a){return G(ba.call(this,function(b,c){return a.call(b,c,b)}))};F.slice=function(a,b){return G(y.call(this,a,b))};var ja=/-([a-z])/g;function H(a){return a.replace(ja,function(a,c){return c.toUpperCase()})}G.camelCase=H;function I(a,b,c){if(c)for(c=a.length;c--&&!1!==b.call(a[c],c,a[c]););else{c=0;for(var d=a.length;c<d&&!1!==b.call(a[c],c,a[c]);c++);}return a}G.each=I;F.each=function(a){return I(this,a)};
F.removeProp=function(a){return this.each(function(b,c){delete c[a]})};G.extend=function(a){for(var b=1;b<arguments.length;b++);b=arguments.length;for(var c=2>b?0:1;c<b;c++)for(var d in arguments[c])a[d]=arguments[c][d];return a};F.extend=function(a){return G.extend(F,a)};G.guid=1;function J(a,b){var c=a&&(a.matches||a.webkitMatchesSelector||a.msMatchesSelector);return!!c&&c.call(a,b)}G.matches=J;function K(a){return!!a&&a===a.window}function E(a){return"function"===typeof a}
function C(a){return"string"===typeof a}function L(a){return!isNaN(parseFloat(a))&&isFinite(a)}G.isWindow=K;G.isFunction=E;G.isString=C;G.isNumeric=L;G.isArray=t;F.prop=function(a,b){if(a){if(C(a))return 2>arguments.length?this[0]&&this[0][a]:this.each(function(c,h){h[a]=b});for(var c in a)this.prop(c,a[c]);return this}};F.get=function(a){return void 0===a?y.call(this):this[0>a?a+this.length:a]};F.eq=function(a){return G(this.get(a))};F.first=function(){return this.eq(0)};F.last=function(){return this.eq(-1)};
function M(a){return C(a)?function(b,c){return J(c,a)}:E(a)?a:a instanceof B?function(b,c){return a.is(c)}:a?function(b,c){return c===a}:function(){return!1}}F.filter=function(a){var b=M(a);return G(w.call(this,function(a,d){return b.call(a,d,a)}))};function N(a,b){return b?a.filter(b):a}var ka=/\S+/g;function O(a){return C(a)?a.match(ka)||[]:[]}F.hasClass=function(a){return!!a&&z.call(this,function(b){return b.classList.contains(a)})};
F.removeAttr=function(a){var b=O(a);return this.each(function(a,d){I(b,function(a,b){d.removeAttribute(b)})})};F.attr=function(a,b){if(a){if(C(a)){if(2>arguments.length){if(!this[0])return;var c=this[0].getAttribute(a);return null===c?void 0:c}return void 0===b?this:null===b?this.removeAttr(a):this.each(function(c,h){h.setAttribute(a,b)})}for(c in a)this.attr(c,a[c]);return this}};
F.toggleClass=function(a,b){var c=O(a),d=void 0!==b;return this.each(function(a,f){I(c,function(a,c){d?b?f.classList.add(c):f.classList.remove(c):f.classList.toggle(c)})})};F.addClass=function(a){return this.toggleClass(a,!0)};F.removeClass=function(a){return arguments.length?this.toggleClass(a,!1):this.attr("class","")};function P(a,b,c){for(var d=[],h=E(b),f=0,n=a.length;f<n;f++)if(h){var l=b(a[f]);l.length&&ca.apply(d,l)}else for(l=a[f][b];null!=l;)d.push(l),l=c?l[b]:null;return d}
function Q(a){return 1<a.length?w.call(a,function(a,c,d){return x.call(d,a)===c}):a}G.unique=Q;F.add=function(a,b){return G(Q(this.get().concat(G(a,b).get())))};function R(a,b,c){if(a&&1===a.nodeType&&b)return a=g.getComputedStyle(a,null),b?c?a.getPropertyValue(b)||void 0:a[b]:a}function S(a,b){return parseInt(R(a,b),10)||0}var T=/^--/,U={},la=p.style,ma=["webkit","moz","ms"];
function na(a,b){void 0===b&&(b=T.test(a));if(b)return a;if(!U[a]){b=H(a);var c=""+b[0].toUpperCase()+b.slice(1);b=(b+" "+ma.join(c+" ")+c).split(" ");I(b,function(b,c){if(c in la)return U[a]=c,!1})}return U[a]}G.prefixedProp=na;var oa={animationIterationCount:!0,columnCount:!0,flexGrow:!0,flexShrink:!0,fontWeight:!0,lineHeight:!0,opacity:!0,order:!0,orphans:!0,widows:!0,zIndex:!0};function pa(a,b,c){void 0===c&&(c=T.test(a));return c||oa[a]||!L(b)?b:b+"px"}
F.css=function(a,b){if(C(a)){var c=T.test(a);a=na(a,c);if(2>arguments.length)return this[0]&&R(this[0],a,c);if(!a)return this;b=pa(a,b,c);return this.each(function(d,f){f&&1===f.nodeType&&(c?f.style.setProperty(a,b):f.style[a]=b)})}for(var d in a)this.css(d,a[d]);return this};function qa(a,b){a=a.dataset[b]||a.dataset[H(b)];try{return JSON.parse(a)}catch(c){}return a}
F.data=function(a,b){if(!a){if(!this[0])return;var c={},d;for(d in this[0].dataset)c[d]=qa(this[0],d);return c}if(C(a))return 2>arguments.length?this[0]&&qa(this[0],a):this.each(function(c,d){c=b;try{c=JSON.stringify(c)}catch(n){}d.dataset[H(a)]=c});for(d in a)this.data(d,a[d]);return this};function ra(a,b){return S(a,"border"+(b?"Left":"Top")+"Width")+S(a,"padding"+(b?"Left":"Top"))+S(a,"padding"+(b?"Right":"Bottom"))+S(a,"border"+(b?"Right":"Bottom")+"Width")}
I([!0,!1],function(a,b){I(["Width","Height"],function(a,d){var c=(b?"outer":"inner")+d;F[c]=function(f){if(this[0])return K(this[0])?g[c]:this[0][(b?"offset":"client")+d]+(f&&b?S(this[0],"margin"+(a?"Top":"Left"))+S(this[0],"margin"+(a?"Bottom":"Right")):0)}})});
I(["width","height"],function(a,b){F[b]=function(c){if(!this[0])return void 0===c?void 0:this;if(!arguments.length)return K(this[0])?this[0][H("outer-"+b)]:this[0].getBoundingClientRect()[b]-ra(this[0],!a);var d=parseInt(c,10);return this.each(function(c,f){f&&1===f.nodeType&&(c=R(f,"boxSizing"),f.style[b]=pa(b,d+("border-box"===c?ra(f,!a):0)))})}});var V={};
F.toggle=function(a){return this.each(function(b,c){if(void 0===a?"none"===R(c,"display"):a){if(c.style.display=c.___cd||"","none"===R(c,"display")){b=c.style;c=c.tagName;if(V[c])c=V[c];else{var d=m(c);e.body.insertBefore(d,null);var h=R(d,"display");e.body.removeChild(d);c=V[c]="none"!==h?h:"block"}b.display=c}}else c.___cd=R(c,"display"),c.style.display="none"})};F.hide=function(){return this.toggle(!1)};F.show=function(){return this.toggle(!0)};
function sa(a,b){return!b||!z.call(b,function(b){return 0>a.indexOf(b)})}var W={focus:"focusin",blur:"focusout"},ta={mouseenter:"mouseover",mouseleave:"mouseout"},ua=/^(mouse|pointer|contextmenu|drag|drop|click|dblclick)/i;function va(a,b,c,d,h){h.guid=h.guid||G.guid++;var f=a.___ce=a.___ce||{};f[b]=f[b]||[];f[b].push([c,d,h]);a.addEventListener(b,h)}function X(a){a=a.split(".");return[a[0],a.slice(1).sort()]}
function Y(a,b,c,d,h){var f=a.___ce=a.___ce||{};if(b)f[b]&&(f[b]=f[b].filter(function(f){var n=f[0],ya=f[1];f=f[2];if(h&&f.guid!==h.guid||!sa(n,c)||d&&d!==ya)return!0;a.removeEventListener(b,f)}));else for(b in f)Y(a,b,c,d,h)}F.off=function(a,b,c){var d=this;if(void 0===a)this.each(function(a,b){Y(b)});else if(C(a))E(b)&&(c=b,b=""),I(O(a),function(a,h){a=X(ta[h]||W[h]||h);var f=a[0],n=a[1];d.each(function(a,d){Y(d,f,n,b,c)})});else for(var h in a)this.off(h,a[h]);return this};
F.on=function(a,b,c,d){var h=this;if(!C(a)){for(var f in a)this.on(f,b,a[f]);return this}E(b)&&(c=b,b="");I(O(a),function(a,f){a=X(ta[f]||W[f]||f);var n=a[0],l=a[1];h.each(function(a,f){a=function za(a){if(!a.namespace||sa(l,a.namespace.split("."))){var h=f;if(b){for(var u=a.target;!J(u,b);){if(u===f)return;u=u.parentNode;if(!u)return}h=u;a.___cd=!0}a.___cd&&Object.defineProperty(a,"currentTarget",{configurable:!0,get:function(){return h}});u=c.call(h,a,a.data);d&&Y(f,n,l,b,za);!1===u&&(a.preventDefault(),
a.stopPropagation())}};a.guid=c.guid=c.guid||G.guid++;va(f,n,l,b,a)})});return this};F.one=function(a,b,c){return this.on(a,b,c,!0)};F.ready=function(a){"loading"!==e.readyState?a(G):e.addEventListener("DOMContentLoaded",function(){a(G)});return this};
F.trigger=function(a,b){if(C(a)){var c=X(a),d=c[0];c=c[1];var h=ua.test(d)?"MouseEvents":"HTMLEvents";a=e.createEvent(h);a.initEvent(d,!0,!0);a.namespace=c.join(".")}a.data=b;var f=a.type in W;return this.each(function(b,c){if(f&&E(c[a.type]))c[a.type]();else c.dispatchEvent(a)})};function wa(a){return a.multiple&&a.options?P(w.call(a.options,function(a){return a.selected&&!a.disabled&&!a.parentNode.disabled}),"value"):a.value||""}var xa=/%20/g,Aa=/file|reset|submit|button|image/i,Ba=/radio|checkbox/i;
F.serialize=function(){var a="";this.each(function(b,c){I(c.elements||[c],function(b,c){c.disabled||!c.name||"FIELDSET"===c.tagName||Aa.test(c.type)||Ba.test(c.type)&&!c.checked||(b=wa(c),void 0!==b&&(b=t(b)?b:[b],I(b,function(b,d){b=a;d="&"+encodeURIComponent(c.name)+"="+encodeURIComponent(d).replace(xa,"+");a=b+d})))})});return a.slice(1)};
F.val=function(a){return void 0===a?this[0]&&wa(this[0]):this.each(function(b,c){if("SELECT"===c.tagName){var d=t(a)?a:null===a?[]:[a];I(c.options,function(a,b){b.selected=0<=d.indexOf(b.value)})}else c.value=null===a?"":a})};F.clone=function(){return this.map(function(a,b){return b.cloneNode(!0)})};F.detach=function(){return this.each(function(a,b){b.parentNode&&b.parentNode.removeChild(b)})};
var Ca=/^\s*<(\w+)[^>]*>/,Da=/^\s*<(\w+)\s*\/?>(?:<\/\1>)?\s*$/,Ea={"*":p,tr:aa,td:r,th:r,thead:q,tbody:q,tfoot:q};function D(a){if(!C(a))return[];if(Da.test(a))return[m(RegExp.$1)];var b=Ca.test(a)&&RegExp.$1;b=Ea[b]||Ea["*"];b.innerHTML=a;return G(b.childNodes).detach().get()}G.parseHTML=D;F.empty=function(){return this.each(function(a,b){for(;b.firstChild;)b.removeChild(b.firstChild)})};F.html=function(a){return void 0===a?this[0]&&this[0].innerHTML:this.each(function(b,c){c.innerHTML=a})};
F.remove=function(){return this.detach().off()};F.text=function(a){return void 0===a?this[0]?this[0].textContent:"":this.each(function(b,c){c.textContent=a})};F.unwrap=function(){this.parent().each(function(a,b){a=G(b);a.replaceWith(a.children())});return this};F.offset=function(){var a=this[0];if(a)return a=a.getBoundingClientRect(),{top:a.top+g.pageYOffset-k.clientTop,left:a.left+g.pageXOffset-k.clientLeft}};F.offsetParent=function(){return G(this[0]&&this[0].offsetParent)};
F.position=function(){var a=this[0];if(a)return{left:a.offsetLeft,top:a.offsetTop}};F.children=function(a){return N(G(Q(P(this,function(a){return a.children}))),a)};F.contents=function(){return G(Q(P(this,function(a){return"IFRAME"===a.tagName?[a.contentDocument]:a.childNodes})))};F.find=function(a){return G(Q(P(this,function(b){return A(a,b)})))};var Fa=/^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g,Ga=/^$|^module$|\/(java|ecma)script/i,Ha=["type","src","nonce","noModule"];
function Ia(a,b){a=G(a);a.filter("script").add(a.find("script")).each(function(a,d){if(Ga.test(d.type)&&k.contains(d)){var c=m("script");c.text=d.textContent.replace(Fa,"");I(Ha,function(a,b){d[b]&&(c[b]=d[b])});b.head.insertBefore(c,null);b.head.removeChild(c)}})}
function Z(a,b,c,d,h,f,n,l){I(a,function(a,f){I(G(f),function(a,f){I(G(b),function(a,b){var l=c?b:f;b=c?f:b;a=a?l.cloneNode(!0):l;h?b.insertBefore(a,d?b.firstElementChild:null):b.parentNode.insertBefore(a,d?b:b.nextElementSibling);Ia(a,b.ownerDocument)},l)},n)},f);return b}F.after=function(){return Z(arguments,this,!1,!1,!1,!0,!0)};F.append=function(){return Z(arguments,this,!1,!1,!0)};F.appendTo=function(a){return Z(arguments,this,!0,!1,!0)};F.before=function(){return Z(arguments,this,!1,!0)};
F.insertAfter=function(a){return Z(arguments,this,!0,!1,!1,!1,!1,!0)};F.insertBefore=function(a){return Z(arguments,this,!0,!0)};F.prepend=function(){return Z(arguments,this,!1,!0,!0,!0,!0)};F.prependTo=function(a){return Z(arguments,this,!0,!0,!0,!1,!1,!0)};F.replaceWith=function(a){return this.before(a).remove()};F.replaceAll=function(a){G(a).replaceWith(this);return this};F.wrapAll=function(a){a=G(a);for(var b=a[0];b.children.length;)b=b.firstElementChild;this.first().before(a);return this.appendTo(b)};
F.wrap=function(a){return this.each(function(b,c){var d=G(a)[0];G(c).wrapAll(b?d.cloneNode(!0):d)})};F.wrapInner=function(a){return this.each(function(b,c){b=G(c);c=b.contents();c.length?c.wrapAll(a):b.append(a)})};F.has=function(a){var b=C(a)?function(b,d){return A(a,d).length}:function(b,d){return d.contains(a)};return this.filter(b)};F.is=function(a){var b=M(a);return z.call(this,function(a,d){return b.call(a,d,a)})};F.next=function(a,b){return N(G(Q(P(this,"nextElementSibling",b))),a)};
F.nextAll=function(a){return this.next(a,!0)};F.not=function(a){var b=M(a);return this.filter(function(a,d){return!b.call(d,a,d)})};F.parent=function(a){return N(G(Q(P(this,"parentNode"))),a)};F.index=function(a){var b=a?G(a)[0]:this[0];a=a?this:G(b).parent().children();return x.call(a,b)};F.closest=function(a){var b=this.filter(a);if(b.length)return b;var c=this.parent();return c.length?c.closest(a):b};F.parents=function(a){return N(G(Q(P(this,"parentElement",!0))),a)};
F.prev=function(a,b){return N(G(Q(P(this,"previousElementSibling",b))),a)};F.prevAll=function(a){return this.prev(a,!0)};F.siblings=function(a){return N(G(Q(P(this,function(a){return G(a).parent().children().not(a)}))),a)};"undefined"!==typeof exports?module.exports=G:g.cash=g.$=G;
})();